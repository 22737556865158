import React from 'react';
import PropTypes from 'prop-types';

import { MenuLayout } from '~/utils/types';
import menuQuery from '../../libs/gql/queries/menus/consumerMenuWithoutSubsectionsQuery.gql';
import menuContainerQuery from '../../libs/gql/queries/menus/menuContainerQuery.gql';

import Menu from './Menu/Menu';
import MenuSkeleton from './MenuSkeleton';
import Query from '../../shared/Query';
import { ServerSideQuerySubtle } from '../../utils/apolloOptimizations';

const MenuContainer = ({ includeItemLink, menuColumnCount, menuId, menuItemCart, selectedMenuSectionId, setSelectedTab, showAddToCartButton, trackPageView }) => {
  const getQuery = menuItemCart ? menuContainerQuery : menuQuery;

  const getVariables = () => {
    const vars = { menuId };
    if (menuItemCart) {
      vars.cartType = menuItemCart.cartType;
      vars.fulfillmentType = menuItemCart.fulfillmentType;
      vars.scheduledAt = menuItemCart.scheduledAt;
    }
    return vars;
  };

  return (
    <Query
      fetchPolicy={'network-only'}
      QueryComponent={ServerSideQuerySubtle}
      query={getQuery}
      variables={getVariables()}
    >
      {({ data, loading }) => {
        if (loading || !data || !data.menu) {
          return (
            <MenuSkeleton
              menuColumnCount={menuColumnCount}
              menuId={menuId}
            />
          );
        }
        return (
          <Menu
            includeItemLink={includeItemLink}
            {...data.menu}
            menuItemCart={menuItemCart}
            menuLayout={showAddToCartButton ? MenuLayout.NextLayout : data.menu.menuLayout}
            menuColumnCount={menuColumnCount || data.menu.menuColumnCount}
            selectedMenuSectionId={selectedMenuSectionId}
            setSelectedTab={setSelectedTab}
            showAddToCartButton={showAddToCartButton}
            trackPageView={trackPageView}
          />
        );
      }}
    </Query>
  );
};

MenuContainer.defaultProps = {
  includeItemLink: true,
  menuColumnCount: null,
  menuId: null,
  menuItemCart: null,
  selectedMenuSectionId: null,
  showAddToCartButton: false,
  trackPageView: false,
};

MenuContainer.propTypes = {
  includeItemLink: PropTypes.bool,
  menuColumnCount: PropTypes.number,
  menuId: PropTypes.number,
  menuItemCart: PropTypes.object,
  selectedMenuSectionId: PropTypes.number,
  showAddToCartButton: PropTypes.bool,
  trackPageView: PropTypes.bool,
};

export default MenuContainer;
