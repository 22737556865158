import React from 'react';
import PropTypes from 'prop-types';

import MenuContainer from './MenuContainer';

const NextMenuGroupTabPanel = (props) => {
  const { includeItemLink, menuColumnCount, menuItemCart, menus, selectedMenuId, showMobileTabs, showAddToCartButton, showTabs, trackPageView } = props;
  if (!showTabs && selectedMenuId) {
    return (
      <MenuContainer
        includeItemLink={includeItemLink}
        menuColumnCount={menuColumnCount}
        menuItemCart={menuItemCart}
        menuId={selectedMenuId}
        showAddToCartButton={showAddToCartButton}
        trackPageView={trackPageView}
      />
    );
  }
  return menus.map((menu) => {
    const selected = !!selectedMenuId && menu.id === selectedMenuId;
    return (
      <div
        key={menu.id}
        aria-labelledby={showMobileTabs ? null : `menu-tab-${menu.id}`}
        hidden={!selected}
        id={`menu-tabpanel-${menu.id}`}
        role="tabpanel"
        tabIndex="0"
      >
        {selected && (
          <MenuContainer
            includeItemLink={includeItemLink}
            menuColumnCount={menuColumnCount}
            menuItemCart={menuItemCart}
            menuId={menu.id}
            showAddToCartButton={showAddToCartButton}
            trackPageView={trackPageView}
          />
        )}
      </div>
    );
  });
};

NextMenuGroupTabPanel.defaultProps = {
  menuColumnCount: null,
  menuItemCart: null,
  selectedMenuId: null,
};

NextMenuGroupTabPanel.propTypes = {
  includeItemLink: PropTypes.bool.isRequired,
  menuColumnCount: PropTypes.number,
  menuItemCart: PropTypes.object,
  menus: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
  })).isRequired,
  selectedMenuId: PropTypes.number,
  showAddToCartButton: PropTypes.bool.isRequired,
  showMobileTabs: PropTypes.bool.isRequired,
  showTabs: PropTypes.bool.isRequired,
  trackPageView: PropTypes.bool.isRequired,
};

export default NextMenuGroupTabPanel;
