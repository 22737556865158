import React from 'react';
import PropTypes from 'prop-types';
import { useApolloClient } from '~/lazy_apollo/client';
import gql from 'graphql-tag';

import MenuSectionSkeleton from '../MenuSectionSkeleton';

const menuFragment = gql`
  fragment MenuSkeletonFragment on Menu {
    __typename
    id
    menuLayout
    enabledSections {
      __typename
      id
      enabledItemsCount
    }
  }
`;

const MenuSkeleton = ({ menuColumnCount, menuId }) => {
  const client = useApolloClient();
  let menu;
  try {
    menu = menuId && client?.readFragment({
      fragment: menuFragment,
      id: `Menu:${menuId}`,
    });
  } catch (err) {
    console.warn(`[POPMENU] Error loading Menu fragment: ${err.toString()}`);
  }
  if (menu && menu.enabledSections) {
    return (
      <div>
        {menu.enabledSections.map(section => (
          <MenuSectionSkeleton
            key={section.id}
            enabledItemsCount={section.enabledItemsCount}
            menuColumnCount={menuColumnCount}
          />
        ))}
      </div>
    );
  }
  return (
    <MenuSectionSkeleton
      menuColumnCount={menuColumnCount}
    />
  );
};

MenuSkeleton.defaultProps = {
  // Defaults to `undefined` so that the MenuSectionSkeleton uses the theme default
  menuColumnCount: undefined,
  menuId: null,
};

MenuSkeleton.propTypes = {
  menuColumnCount: PropTypes.number,
  menuId: PropTypes.number,
};

export default MenuSkeleton;
